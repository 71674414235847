import { GP_ACCESS_TOKEN_COOKIE } from '~/plugins/auth/constants';
import { v4 as uuidv4 } from 'uuid';

const useAuth = () => {
  const route = useRoute();
  const { $auth } = useNuxtApp();
  const accessTokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE);
  const i18n = useI18n();
  const config = useRuntimeConfig();
  const keycloakOIDCUrl = computed(
    () =>
      `${config.public.auth.url}/realms/${config.public.auth.realm}/protocol/openid-connect`,
  );

  const login = async (redirectPath?: string, provider?: string) => {
    const toRedirectPath =
      redirectPath != null && typeof redirectPath === 'string'
        ? redirectPath
        : route.fullPath;

    let url =
      `${keycloakOIDCUrl.value}/auth` +
      `?client_id=${encodeURIComponent(
        config.public.auth.clientId,
      )}&redirect_uri=${encodeURIComponent(
        `${config.public.websiteUrl}${toRedirectPath}`,
      )}&state=${encodeURIComponent(
        uuidv4(),
      )}&response_mode=query&response_type=code&scope=${encodeURIComponent(
        'openid email profile gportal',
      )}`;

    if (provider != null && provider.length > 0) {
      url += `&kc_idp_hint=${provider}`;
    }

    navigateTo(url, {
      external: true,
    });
  };

  const logout = async (redirectPath?: string) => {
    let toRedirectPath = null;
    if (route.meta.auth) {
      // when logging out from a auth protected page, redirect to home page
      toRedirectPath = `/${i18n.locale.value}`;
    } else if (redirectPath != null && typeof redirectPath === 'string') {
      // customized logout path
      toRedirectPath = redirectPath;
    } else {
      // stay on page after logging out
      toRedirectPath = route.fullPath;
    }

    const url =
      `${keycloakOIDCUrl.value}/logout` +
      `?post_logout_redirect_uri=${encodeURIComponent(
        `${config.public.websiteUrl}${toRedirectPath}`,
      )}&id_token_hint=${encodeURIComponent($auth.storedTokenSet.id_token)}`;

    // force removal of access token from the cookies, so after the redirect from keycloak to nuxt,
    // we do not want the server to fetch the user data and sets the store
    accessTokenCookie.value = undefined;
    $auth.clearTokenStorage();

    navigateTo(url, {
      external: true,
    });
  };

  return {
    login,
    logout,
  };
};

export default useAuth;
